<template>
  <div :class="ui.wrapper" v-bind="attrs">
    <slot name="top"/>

    <div :class="ui.video">
      <slot name="video"/>
    </div>

    <UContainer :class="ui.container">
      <div :class="ui.base">

        <div v-if="$slots.headline" :class="ui.headline">
          <slot name="headline"/>
        </div>

        <h1 :class="ui.title">
          <slot name="title">
            {{ title }}
          </slot>
        </h1>

        <div v-if="description || $slots.description" :class="ui.description">
          <slot name="description">
            {{ description }}
          </slot>
        </div>

        <div v-if="links?.length || $slots.links" :class="ui.links">
          <slot name="links">
            <UButton
              v-for="(link, index) in links"
              :key="index"
              v-bind="link"
              @click="link.click"
            />
          </slot>
        </div>
      </div>

      <slot/>
    </UContainer>

    <slot name="bottom"/>
  </div>
</template>

<script setup lang="ts">
import type { Button, DeepPartial } from '#ui/types'
import type { PropType } from 'vue'

import { twJoin } from 'tailwind-merge'

defineOptions({
  inheritAttrs: false,
})

const props = defineProps({
  class: {
    default: '',
    type: [ String, Object, Array ] as PropType<unknown>,
  },
  description: {
    default: '',
    type: String,
  },
  links: {
    default: () => [],
    type: Array as PropType<({ click?: (...args: unknown[]) => void } & Button)[]>,
  },
  orientation: {
    default: 'vertical',
    type: String as PropType<'horizontal' | 'vertical'>,
  },
  title: {
    default: '',
    type: String,
  },
  ui: {
    default: () => ({}),
    type: Object as PropType<DeepPartial<typeof config.value>>,
  },
})

const config = computed(() => {
  const container: string = twJoin(
    'gap-16 sm:gap-y-24 relative z-10',
    props.orientation === 'vertical' && 'flex flex-col',
    props.orientation === 'horizontal' && 'grid lg:grid-cols-2 lg:items-center',
  )

  const base: string = props.orientation === 'vertical' ? 'text-center' : ''

  const links: string = twJoin(
    'mt-10 flex flex-wrap gap-x-6 gap-y-3 relative',
    props.orientation === 'vertical' && 'justify-center',
  )

  return {
    base,
    container,
    description: 'mt-6 text-lg tracking-tight text-gray-600 dark:text-gray-300',
    headline: 'mb-10',
    links,
    title: 'text-5xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-7xl',
    video: 'absolute top-0 left-0 min-w-full h-full',
    wrapper: 'py-24 sm:py-32 md:py-40 relative overflow-hidden',
  }
})

const { attrs, ui } = useUI('landing.hero', toRef(props, 'ui'), config, toRef(props, 'class'), true)
</script>
