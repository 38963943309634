<script setup lang="ts">
import { useTimeoutFn } from '@vueuse/core'

const route = useRoute()
const { data: pageContent } = await useAsyncData(`content:pages${route.path}`, () => queryContent(`pages${route.path}`).findOne())

useSeoMeta({
  description: pageContent.value?.description,
  ogDescription: pageContent.value?.description,
  ogTitle: pageContent.value?.title,
  title: pageContent.value?.title,
})

 import.meta.prerender && defineOgImageComponent('LandingCard', {
  description: pageContent.value?.hero.description,
  title: pageContent.value?.hero.title,
})

type VideoSource = {
  media: string
  src: string
  type: string
}
const heroVideoHeights = [ 240, 360, 480, 720, 1080, 1440, 2160 ]
const videoRatio = 1.0 / 0.7
const createVideoSource = ({ height, isLargest, isSmallest, minHeight, type }: { height: number, isLargest: boolean, isSmallest: boolean, minHeight: number, type: string }) => ({
  media: isSmallest ? `all and (max-height: ${Math.floor(height * videoRatio)}px)` : isLargest ? `all and (min-height: ${Math.floor(minHeight * videoRatio)}px)` : `all and (min-height: ${Math.floor(minHeight * videoRatio)}px) and (max-height: ${Math.floor(height * videoRatio)}px)`,
  src: `/videos/hero-background.v1.${height}.${type}`,
  type: `video/${type}`,
})
const heroVideoSources = heroVideoHeights.reduce((sources, height, index, array) => {
  const minHeight = index > 0 ? (heroVideoHeights[index - 1] ?? 0) + 1 : 0
  const isSmallest = index === 0
  const isLargest = index === array.length - 1
  sources.push(
    createVideoSource({ height, isLargest, isSmallest, minHeight, type: 'mp4' }),
    createVideoSource({ height, isLargest, isSmallest, minHeight, type: 'webm' }),
  )
  return sources
}, [] as VideoSource[])

const carouselRef = ref()
const carouselTimeout = useTimeoutFn(() => {
  if (carouselRef.value?.page === carouselRef.value?.pages) {
    carouselRef.value?.select(0)
  } else {
    carouselRef.value.next()
  }
}, 6000)

watch(() => carouselRef.value?.page, () => {
  carouselTimeout.stop()
  carouselTimeout.start()
}, { immediate: true })
</script>

<template>
  <VideoHero
    :title="pageContent?.hero.title"
    :description="pageContent?.hero.description"
    :links="pageContent?.hero.links"
    class="min-h-[calc(100vh*.7)]"
    :ui="{
      wrapper: 'flex flex-col h-full justify-center',
      title: 'w-min m-auto font-extrabold dark:text-gray-50 text-gray-950 mb-2 min-[320px]:text-nowrap',
      description: 'max-w-xs sm:max-w-sm m-auto font-semibold tracking-normal dark:text-gray-50 text-gray-950 md:text-2xl text-pretty',
    }"
  >

    <template #video>
      <video
        autoplay
        muted
        playsinline
        loop
        class="w-auto h-full object-cover m-auto opacity-80 invert contrast-[96%] dark:invert-0 dark:brightness-[90%] dark:contrast-[92%]"
      >
        <source
          v-for="(videoSource, index) in heroVideoSources"
          :key="index"
          :src="videoSource.src"
          :type="videoSource.type"
          :media="videoSource.media"
        >
      </video>
    </template>
  </VideoHero>

  <ULandingSection>
    <UCarousel
      v-slot="{ item, index }"
      ref="carouselRef"
      :items="pageContent?.carousel?.images"
      :ui="{ item: 'basis-full' }"
      class="rounded-xl md:rounded-3xl overflow-hidden"
    >
      <NuxtImg
        :src="item.src"
        :preload="index < 2"
        :loading="index < 2 ? 'eager' : 'lazy'"
        :alt="item.alt"
        class="w-full object-cover"
        draggable="false"
        fit="cover"
        format="webp"
        sizes="200px sm:100vw md:100vw lg:1240px"
      /></UCarousel>

    <ULandingLogos
      :title="pageContent?.logos.title"
      class="mt-4 lg:mt-8"
      align="center"
      :ui="{
        'images': 'flex-nowrap'
      }"
    >
      <UTooltip
        v-for="(icon, index) in pageContent?.logos.icons"
        :key="index"
        :text="icon.label"
        class="size-12 lg:size-16 flex-shrink"
      >
        <ULink
          :to="icon.to"
          :aria-description="icon.description"
          :aria-label="icon.label"
          class="size-full"
        >
          <UIcon
            :name="icon.name"
            :alt="`Icon depicting the ${icon.label.toLowerCase()} industry.`"
            class="size-full text-gray-900 dark:text-white hover:bg-primary-600"
          />
        </ULink>
      </UTooltip>
    </ULandingLogos>
  </ULandingSection>

  <ULandingSection
    :title="pageContent?.features.title"
    :description="pageContent?.features.description"
    :headline="pageContent?.features.headline"
  >
    <UPageGrid
      :id="pageContent?.features.id"
      class="scroll-mt-[calc(var(--header-height)+140px+128px+96px)]"
    >
      <ULandingCard
        v-for="(item, index) in pageContent?.features.items"
        :key="index"
        v-bind="item"
      />
    </UPageGrid>
  </ULandingSection>

  <ULandingSection
    :title="pageContent?.pricing.title"
    :description="pageContent?.pricing.description"
    :headline="pageContent?.pricing.headline"
  >
    <UPricingGrid
      id="pricing"
      compact
      class="scroll-mt-[calc(var(--header-height)+140px+128px+96px)]"
    >
      <UPricingCard
        v-for="(plan, index) in pageContent?.pricing.plans"
        :key="index"
        v-bind="plan"
        :ui="{
          highlight: 'ring-gray-900 dark:ring-white',
        }"
      />
    </UPricingGrid>
  </ULandingSection>

  <!-- <ULandingSection
      :headline="pageContent?.testimonials.headline"
      :title="pageContent?.testimonials.title"
      :description="pageContent?.testimonials.description"
    >
      <UPageColumns
        id="testimonials"
        class="xl:columns-4 scroll-mt-[calc(var(--header-height)+140px+128px+96px)]"
      >
        <div
          v-for="(testimonial, index) in pageContent?.testimonials.items"
          :key="index"
          class="break-inside-avoid"
        >
          <ULandingTestimonial v-bind="testimonial"/>
        </div>
      </UPageColumns>
    </ULandingSection> -->

  <ULandingSection class="relative overflow-hidden">
    <DotPatternBackground/>

    <ULandingCTA
      v-bind="
        pageContent?.cta"
      :card="false"
    />
  </ULandingSection>

  <ULandingSection
    id="faq"
    :title="pageContent?.faq.title"
    :description="pageContent?.faq.description"
    class="scroll-mt-[var(--header-height)]"
  >
    <ULandingFAQ
      multiple
      :items="pageContent?.faq.questions"
      :ui="{
        button: {
          label: 'font-semibold',
          trailingIcon: {
            base: 'w-6 h-6'
          }
        }
      }"
      class="max-w-4xl mx-auto"
    />
  </ULandingSection>

  <ConnectCard/>
</template>
